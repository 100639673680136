var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Enabled", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "enabled", $$v)
                    },
                    expression: "formData.enabled"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Full Name", prop: "fullName" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "fullName",
                    required: "",
                    placeholder: "Full Name"
                  },
                  model: {
                    value: _vm.formData.fullName,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "fullName", $$v)
                    },
                    expression: "formData.fullName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Company", prop: "companyName" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "companyName",
                    required: "",
                    placeholder: "Enter company name"
                  },
                  model: {
                    value: _vm.formData.companyName,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "companyName", $$v)
                    },
                    expression: "formData.companyName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Email", prop: "emailAddress" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "emailAddress",
                    required: "",
                    placeholder: "Email"
                  },
                  model: {
                    value: _vm.formData.emailAddress,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "emailAddress", $$v)
                    },
                    expression: "formData.emailAddress"
                  }
                })
              ],
              1
            ),
            !_vm.isEdit
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Password", prop: "password" } },
                  [
                    _c("el-input", {
                      attrs: {
                        name: "password",
                        required: "",
                        placeholder: "Password",
                        "show-password": ""
                      },
                      model: {
                        value: _vm.formData.password,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "password", $$v)
                        },
                        expression: "formData.password"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "Mobile Number", prop: "mobileNumber" } },
              [
                _c("el-input", {
                  attrs: { name: "mobileNumber", placeholder: "Mobile Number" },
                  model: {
                    value: _vm.formData.mobileNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "mobileNumber", $$v)
                    },
                    expression: "formData.mobileNumber"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Wallet Usable Amount",
                  prop: "walletUsageAmount"
                }
              },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "walletUsageAmount",
                    required: "",
                    placeholder: "Wallet Usable Amount",
                    min: 0
                  },
                  model: {
                    value: _vm.formData.walletUsageAmount,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "walletUsageAmount", $$v)
                    },
                    expression: "formData.walletUsageAmount"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Users Role", required: "" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      name: "usersRoleId",
                      placeholder: "Select"
                    },
                    model: {
                      value: _vm.formData.usersRoleId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "usersRoleId", $$v)
                      },
                      expression: "formData.usersRoleId"
                    }
                  },
                  _vm._l(_vm.userRoleList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Avatar", prop: "avatar" } },
              [
                _c("file-upload", {
                  attrs: { multiple: false, "file-list": _vm.avatar },
                  on: { change: _vm.fileUploaded }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }