import { render, staticRenderFns } from "./UserDetail.vue?vue&type=template&id=0fa67678&scoped=true&"
import script from "./UserDetail.vue?vue&type=script&lang=ts&"
export * from "./UserDetail.vue?vue&type=script&lang=ts&"
import style0 from "./UserDetail.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./UserDetail.vue?vue&type=style&index=1&id=0fa67678&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa67678",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/sandeshbhoir/Documents/vue/biggest-supplier-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0fa67678')) {
      api.createRecord('0fa67678', component.options)
    } else {
      api.reload('0fa67678', component.options)
    }
    module.hot.accept("./UserDetail.vue?vue&type=template&id=0fa67678&scoped=true&", function () {
      api.rerender('0fa67678', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/users/components/UserDetail.vue"
export default component.exports